.section{
    padding: 70px 0;
    position: relative;
    background-color: #FFFFFF;
    &.contact{
        padding: 70px 0 0 0;
    }
}
.section-with-space{
    padding: 60px 0;
}
.section-gray{
    background-color: #EEEEEE;
}
.section-nude{
    background-color: $bg-nude;
}
.section-gold{
    background-color: #caac90;
    color: $white-color;
}
.section-brown{
    background-color: #A59E94;
    color: $white-color;
}
.section-light-blue{
    background-color: $primary-color;
    color: $white-color;
}
.section-dark-blue{
    background-color: #506367;
    color: $white-color;
}
.section-dark{
    background-color: #0b1011;
}
.section-image,
.section-gold,
.section-dark{
    position: relative;

    .title,
    .info-title,
    .card-plain .card-title{
        color: $white-color !important;
    }
    .category,
    .description,
    .card-plain .card-description,
    .card-plain .card-category{
        color: rgba($white-color, .7);
    }
    hr{
        border-color: rgba(255, 255, 255, 0.19);
    }
}
.page-header,
.carousel-caption{
    .title,
    .info-title{
        color: $white-color;
    }
}
[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"]{
    padding: 80px 0;
}
.section-image{
    background-position: center center;
    background-size: cover;

    &:before{
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    & .container{
        position: relative;
        z-index: 2;
    }
}

.section-login{
    min-height: 700px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .page-header{
        .content-center{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }
        .presentation-title{
            background: none !important;
        }
    }
}


[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
    padding: 80px 0;
}
.section-image {
    background-position: center center;
    background-size: cover;

    &:before {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    & .container {
        position: relative;
        z-index: 2;
    }
}
.section{
    .sharing-area{
        margin-top: 0;
    }
    .google-maps{
        @media (max-width: 767px){
            height: 300px!important;
        }
    }
}

.price-table{
    font-size: 18px;
    text-align: left;
    @media (max-width: 639px){
        font-size: 16px;
    }
    tr{
        @media (max-width: 639px){
           display: flex;
           flex-direction: column;
        }
        td{
            padding: 10px 0;
            @media (max-width: 639px){
                padding: 3px 0;
            }
        }
    }
    &.responsiveTable{
        tbody{
            tr{
                @media (max-width: 639px){
                    border: 1px solid #A49E9E;
                }
                td{
                    &:first-child{
                        @media (max-width: 639px){
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        td.pivoted{
            width: 100%;
            padding-left: 0!important;
            @media (max-width: 639px){
                text-align: center!important;
            }
        }
    }
}