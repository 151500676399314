.hide {
    display: none !important;
}

.color--white {
    color: white
}

.page-header {
    margin-top: 65px;
}

.logo__img {
    display: block;
    margin: 6px 12px;
}

video,
iframe {
    display: block;
    border: 0;
}


.iframe-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.iframe-wrapper--always {
    padding-bottom: 56.25%;
}

@media (max-width: 479px) {
    .iframe-wrapper--map {
        padding-bottom: 84.375%;
    }
}

@media (min-width: 480px) and (max-width: 1023px) {
    .iframe-wrapper--map {
        padding-bottom: 56.25%;
    }
}

@media (min-width: 1024px) {
    .iframe-wrapper--map {
        padding-bottom: 28.125%;
    }
}

.iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}


html {
    position: relative;
}

.sidebar-collapse:before {
    content: '';
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    visibility: hidden;
}

.nav-open .sidebar-collapse:before {
    opacity: 1;
    visibility: visible;
}

.navbar-translate {
    display: flex;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: #000 !important;
    display: inline-flex;
    align-items: center;
}

.navbar .navbar-nav .nav-item .nav-link:hover {
    color: #777777;
}

.navbar .navbar-nav .nav-item .nav-link p {
    padding: 0;
    color: #000
}

.navbar .navbar-collapse:after {
    background: white;
}

@media (max-width: 639px) {
    .price-table {
        border: 1px solid #A49E9E
    }

    .page-header.page-header-small {
        min-height: 20vh !important;
    }
}

@media (min-width: 640px) {
    .price-table.responsiveTable td.pivoted {
        width: auto;
    }

    .price-table.responsiveTable td.pivoted:nth-child(2) {
        text-align: right;
    }
}

a.btn {
    display: inline-flex;
    align-items: center;
}

.social-icon {
    width: 17px;
    height: auto;
}

/* https://codepen.io/sosuke/pen/Pjoqqp */
.social-icon--white {
    filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(270deg) brightness(93%) contrast(111%);
}

.social-icon--instagram {
    filter: invert(24%) sepia(88%) saturate(1894%) hue-rotate(295deg) brightness(88%) contrast(93%);
}

.social-icon--facebook {
    filter: invert(33%) sepia(45%) saturate(1604%) hue-rotate(182deg) brightness(94%) contrast(90%);
}

.btn-instagram-bg {
    background-color: #bc2a8d;
    border-color: #bc2a8d;
}

.btn-instagram-bg:hover {
    background-color: #bc2a8d;
    border-color: #bc2a8d;
}

.footer nav > ul a:not(.btn) {
    color: black
}

.owner .avatar {
    max-width: none;
}

@media (min-width: 1024px) {
    .owner .avatar {
        margin-top: -140px;
    }

    .owner .avatar img {
        width: 300px;
        height: auto;
    }
}